<template>
  <div class="message-table-wrap">
    <el-table
      :data="tableData"
      height="100%"
      border
      style="width: 100%:height:100%"
      v-loading="tableLoading"
      element-loading-text="拼命加载中..."
    >
      <el-table-column prop="read" label="阅读状态" width="80" align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.read">已读</el-tag>
          <el-tag type="danger" v-if="!scope.row.read">未读</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="content"
        label="内容"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span
            :class="[!scope.row.read ? 'message-not-read' : 'message-read']"
            :title="!scope.row.read ? '点击设置为已读' : '已读'"
            @click="handleSetRead(scope.row)"
            >{{ scope.row.content }}</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="clientInvitationId" label="客户邀约" width="80">
        <template slot-scope="scope">
          <el-button type="text" @click="handleDetail(scope.row)">
            查看邀约
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="clientInvitationId" label="客户" width="150">
        <template slot-scope="scope">
          <el-button type="text" @click="handleClientDetail(scope.row)">
            {{ scope.row.clientInvitationClientName }}-{{
              scope.row.clientInvitationClientMainContactPhoneNo
            }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="control" label="操作" width="80">
        <template slot-scope="scope">
          <el-button
            v-if="!scope.row.read"
            type="text"
            @click="handleSetRead(scope.row)"
          >
            标记已读
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagenation-wrap">
      <el-pagination
        class="base-table-pagination"
        :disabled="tableLoading"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page + 1"
        :page-sizes="[10, 20, 30, 40, 50, 60]"
        :page-size="size"
        :layout="pageLayout"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  GetClientInvitationsMessagesList,
  PutClientInvitationsMessagesRead,
} from "../api/message";
import { ShowApiError } from "@/request/error";
export default {
  data() {
    return {
      tableLoading: false,
      page: 0,
      size: 20,
      total: 0,
      tableData: [],
      pageLayout: "total, sizes, prev, pager, next, jumper",
      orderProp: "createdAt",
      orderDirection: "descending",
      orderPropObj: {},
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val - 1;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.page = 0;
      this.size = val;
      this.getTableData();
    },
    getTableData() {
      this.tableLoading = true;
      GetClientInvitationsMessagesList({
        page: this.page,
        size: this.size,
        isRead: false,
        needRemind: true,
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content;
          this.total = totalElements;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("拉取数据错误", err);
        });
    },
    handleSetRead(row) {
      if (row.read) {
        return false;
      }
      PutClientInvitationsMessagesRead(row.id)
        .then(() => {
          this.getTableData();
          this.$store.dispatch("app/getGlobalMessageRemindCount");
        })
        .catch((err) => {
          ShowApiError("操作失败", err);
        });
    },
    handleDetail(row) {
      this.$emit("showdateil", row.clientInvitationId);
    },
    handleClientDetail(row) {
      this.$emit("close");
      this.$router.push({
        name: "CientsDetail",
        params: {
          id: row.clientInvitationClientId,
        },
        query: {
          name: row.clientInvitationClientName,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.message-table-wrap {
  box-sizing: border-box;
  width: 100%;
  height: 450px;
  padding-bottom: 50px;
  position: relative;
  overflow: hidden;
  .pagenation-wrap {
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>