<template>
  <section class="filter-wrap base-shadow">
    <el-form ref="form" :model="tableFilters" label-width="auto">
      <el-row :gutter="20">
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="客户">
            <BasicSelect
              :multiple="true"
              v-model="tableFilters.clientId"
              :allOptions="_clientOptions"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="接待人">
            <BasicSelect
              :multiple="true"
              v-model="tableFilters.followUpUsersId"
              :allOptions="_ownerOptions"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="负责人(多选)">
            <BasicSelect
              multiple
              v-model="tableFilters.ownerId"
              :allOptions="_ownerOptions"
            />
          </el-form-item>
        </el-col>

        <template v-if="showMoreFilter">
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="考察情况">
              <BasicSelect
                v-model="tableFilters.statusId"
                :allOptions="statusesOptions"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="邀约类型">
              <BasicSelect
                v-model="tableFilters.typeId"
                :allOptions="typeOptions"
              />
            </el-form-item>
          </el-col>
          
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="到访情况">
              <BasicSelect
                v-model="tableFilters.visitStatusId"
                :allOptions="visitStatusesOptions"
              />
            </el-form-item>
          </el-col>
          
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="创建日期">
              <el-date-picker
                v-model="tableFilters.createdAt"
                type="daterange"
                style="width: 100%"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="部门(多选)">
              <el-cascader
                style="width: 100%"
                :options="_departmnetTreeOptions"
                v-model="tableFilters.departmentIds"
                collapse-tags
                :props="{
                  multiple: true,
                  emitPath: false,
                  label: 'name',
                  value: 'id',
                  children: 'subs',
                }"
                clearable
              ></el-cascader>
              <!-- <BasicSelect
              multiple
              v-model="tableFilters.departmentId"
              :allOptions="departmentOptions"
            /> -->
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="考察日期">
              <el-date-picker
                v-model="tableFilters.visitDate"
                type="daterange"
                style="width: 100%"
                value-format="yyyy/MM/dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
              <!-- <el-date-picker
                style="width: 100%"
                v-model="tableFilters.visitDate"
                type="date"
                value-format="yyyy/MM/dd"
                placeholder="点击选择"
              >
              </el-date-picker> -->
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="启/禁状态">
              <el-select
                v-model="tableFilters.disabled"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option label="启用" :value="false"> </el-option>
                <el-option label="禁用" :value="true"> </el-option>
                <el-option label="全部" :value="null"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="最后修改日期">
              <el-date-picker
                v-model="tableFilters.lastModifiedAt"
                type="daterange"
                style="width: 100%"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="创建人">
              <BasicSelect
                v-model="tableFilters.createdById"
                :allOptions="_userOptions"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="最后修改人">
              <BasicSelect
                v-model="tableFilters.lastModifiedById"
                :allOptions="_userOptions"
              />
            </el-form-item>
          </el-col>
        </template>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item>
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button @click="onResetFilter">重置</el-button>
            <el-button type="text" @click="showMoreFilter = !showMoreFilter">
              更多
              <i
                :class="
                  showMoreFilter ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                "
              ></i>
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </section>
</template>

<script>
import BasicSelect from "@/components/BasicSelect";
import formSelection from "../mixins/formSelection";
import { onClearFilter } from "@/utils/common";
import { GetTableFormatParams } from "@/utils/table";
export default {
  components: {
    BasicSelect,
  },
  mixins: [formSelection],
  data() {
    return {
      showMoreFilter: false,
      tableFilters: {
        createdAt: [],
        lastModifiedAt: [],
        createdById: null,
        lastModifiedById: null,
        followUpUsersId: null,
        clientId: null,
        visitDate: null,
        disabled: false,
        ownerId: null,
        statusId: null,
        typeId: null,
        visitStatusId:null,
        departmentIds: [],
      },
      propObj: {
        followUpUsersId: "followUpUsers.id",
        ownerId: "client.owner.id",
        departmentIds: "client.owner.department.id",
      }
    };
  },
 
  computed: {
    _userOptions() {
      return this.$store.getters.userOptions;
    },
    _ownerOptions() {
      return this.$store.getters.ownerOptions;
    },
    _departmnetTreeOptions() {
      return this.$store.getters.departmnetTreeOptions;
    },
  },

  watch: {
    $route: {
      handler() {
        if (this.$route.name === "ClientInvitationsList") {
          if (Object.keys(this.$route.params).length > 0) {
            for (const key in this.$route.params) {
              if (Object.hasOwnProperty.call(this.tableFilters, key)) {
                this.tableFilters[key] = this.$route.params[key];
              }
            }
            this.showMoreFilter = true;
            this.onSearch();
          }else{
            this.onSearch();
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
   created() {
    this.initFormSelection();
  },
  methods: {
    onSearch() {
      let result = GetTableFormatParams(this.propObj, this.tableFilters);
      this.$emit("change", result);
      this.$emit("confirm", result);
    },
    onResetFilter() {
      this.tableFilters = onClearFilter(this.tableFilters);
      this.tableFilters.disabled = false
      let result = GetTableFormatParams(this.propObj, this.tableFilters);
      this.$store.dispatch("basicOption/initUserOption");
      this.$store.dispatch("basicOption/initOwnerOption");
      this.$store.dispatch("basicOption/initDepartmentTreeOption");
      this.$emit("change", result);
      this.$emit("clear", result);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-wrap {
  box-sizing: border-box;
  margin-top: 10px;
  padding: 22px;
  padding-bottom: 0;
  background-color: #fff;
}
</style>