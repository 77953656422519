<template>
  <section class="base-page">
    <PageHeader class="base-page-header base-shadow">
      <template #right>
        <el-button
          class="mt-r"
          size="small"
          @click="addRow"
          type="primary"
          v-if="checkPermission(['CLIENT_ADMIN', 'CLIENT_USER'])"
          >新增</el-button
        >
        <el-button
          size="small"
          class="mt-r"
          @click="multipleFormDialog = true"
          v-if="checkPermission(['CLIENT_ADMIN', 'CLIENT_USER'])"
          >批量新增</el-button
        >
        <el-button class="float-r" size="small" @click="handleShowMessage"
          ><i class="el-icon-bell"></i> 通知</el-button
        ></template
      >
    </PageHeader>
    <keep-alive><FilterSection @change="onFilterChange" /></keep-alive>
    <BasicTable
      showSelection
      hiddenClear
      :loading="tableLoading"
      :tableColumns="tableColumns"
      :tableData="tableData"
      :page="page"
      :size="size"
      :total="total"
      :orderDirection="orderDirection"
      :orderProp="orderProp"
      @onSortChange="onTableSortChange"
      @onSizeChange="handleSizeChange"
      @onPageChange="handlePageChange"
      @onRefresh="onRefresh"
      @onClearAndRefresh="onClearAndRefresh"
      @onSelectionChange="onSelectionChange"
    >
      <template #tableControl> </template>

      <template v-for="column in tableColumns">
        <el-table-column
          v-if="column.prop !== 'controlColumn' && column.showColumn"
          v-bind="column"
          :show-overflow-tooltip="true"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <span v-if="column.prop === 'createdAt'">{{
              scope.row.createdAt | date_time
            }}</span>
            <span v-else-if="column.prop === 'lastModifiedAt'">
              {{ scope.row.createdAt | date_time }}
            </span>
            <span v-else-if="column.prop === 'status'">
              {{ scope.row.statusName }}
            </span>
            <span
              v-else-if="column.prop === 'clientName'"
              class="text-nav"
              @click="showClientDetail(scope.row)"
            >
              {{ scope.row.clientName }} -
              {{
                scope.row.clientAddress !== null
                  ? scope.row.clientAddress.province +
                    scope.row.clientAddress.region
                  : ""
              }}
            </span>
            <span v-else-if="column.prop === 'disabled'">
              <el-tag type="success" v-if="!scope.row.disabled">已启用</el-tag>
              <el-tag type="danger" v-if="scope.row.disabled">已禁用</el-tag>
            </span>
            <span v-else-if="column.prop === 'followUpUsers'">
              <span v-if="scope.row.followUpUsers.length <= 0">/</span>
              <span v-else-if="scope.row.followUpUsers.length <= 1">{{
                scope.row.followUpUsers[0].name
              }}</span>
              <el-popover v-else placement="left" width="300" trigger="click">
                <el-tag
                  class="tag"
                  v-for="item in scope.row.followUpUsers"
                  :key="item.id"
                  type="info"
                  style="margin-right: 10px; margin-bottom: 10px"
                >
                  <i class="el-icon-paperclip"></i>
                  {{ item.name }}
                </el-tag>
                <el-button slot="reference" type="text" icon="el-icon-view"
                  >{{ scope.row.followUpUsers[0].name }}（{{
                    scope.row.followUpUsers.length
                  }}个）</el-button
                >
              </el-popover>
            </span>
            <span v-else>{{ scope.row[column.prop] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="column.prop === 'controlColumn' && column.showColumn"
          v-bind="column"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <el-button
              @click.native.prevent="showRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              详情
            </el-button>
            <!-- <el-button
              @click.native.prevent="handleMessageAdd(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              写提醒
            </el-button> -->
            <!-- <el-button
              :loading="scope.row.canUpdateChecking"
              @click.native.prevent="editRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              {{ scope.row.canUpdateChecking ? "权限检验中..." : "修改" }}
            </el-button>
            <el-button
              class="text-danger"
              v-if="!scope.row.disabled"
              @click.native.prevent="disableRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              {{ scope.row.canDisableChecking ? "权限检验中..." : "禁用" }}
            </el-button>
            <el-button
              class="text-success"
              v-if="scope.row.disabled"
              @click.native.prevent="enableRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              {{ scope.row.canDisableChecking ? "权限检验中..." : "启用" }}
            </el-button> -->
            <el-dropdown>
              <span class="el-dropdown-link drop-down-menu-link">
                更多操作<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown" :hide-on-click="false">
                <el-dropdown-item
                  @click.native.prevent="
                    handleMessageAdd(scope.row, scope.$index)
                  "
                  >写提醒</el-dropdown-item
                >
                <el-dropdown-item
                  :disabled="scope.row.canUpdateChecking"
                  @click.native.prevent="editRow(scope.row, scope.$index)"
                  >{{
                    scope.row.canUpdateChecking ? "权限检验中..." : "修改"
                  }}</el-dropdown-item
                >

                <el-dropdown-item
                  v-if="!scope.row.disabled"
                  :disabled="scope.row.canDisableChecking"
                  @click.native.prevent="disableRow(scope.row, scope.$index)"
                  >{{
                    scope.row.canDisableChecking ? "权限检验中..." : "禁用"
                  }}</el-dropdown-item
                >
                <el-dropdown-item
                  v-if="scope.row.disabled"
                  :disabled="scope.row.canDisableChecking"
                  @click.native.prevent="enableRow(scope.row, scope.$index)"
                  >{{
                    scope.row.canDisableChecking ? "权限检验中..." : "启用"
                  }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </template>
      <template #multipleControl>
        <el-button
          v-if="
            checkPermission([
              'CLIENT_ADMIN',
              'CLIENT_GROUP_ADMIN',
              'CLIENT_USER',
            ])
          "
          type="success"
          size="small"
          @click="onMultipleToggleDisable(false)"
          :disabled="multipleSelected.length <= 0"
          :loading="multipleUndisableLoading"
          >{{
            multipleUndisableLoading ? multipleDisableLoadingText : "批量启用"
          }}
          {{ multipleSelected.length }}</el-button
        >
        <el-button
          v-if="
            checkPermission([
              'CLIENT_ADMIN',
              'CLIENT_GROUP_ADMIN',
              'CLIENT_USER',
            ])
          "
          type="danger"
          size="small"
          @click="onMultipleToggleDisable(true)"
          :disabled="multipleSelected.length <= 0"
          :loading="multipleDisableLoading"
          >{{
            multipleDisableLoading ? multipleDisableLoadingText : "批量禁用"
          }}
          {{ multipleSelected.length }}</el-button
        >
      </template>
    </BasicTable>
    <el-dialog
      :title="isEmprty(currentId) ? '新增邀约客户' : '修改邀约客户'"
      :visible.sync="formDialog"
      append-to-body
      :close-on-click-modal="false"
      width="600px"
    >
      <Form :id="currentId" @cancel="onFormCancel" @success="onFormSuccess" />
    </el-dialog>
    <el-dialog
      title="邀约详情"
      :visible.sync="detaiDialog"
      append-to-body
      :close-on-click-modal="false"
      width="600px"
      @close="currentId = ''"
    >
      <Detail :id="currentId" />
    </el-dialog>
    <el-dialog
      title="批量新增邀约客户"
      :visible.sync="multipleFormDialog"
      append-to-body
      :close-on-click-modal="false"
      width="90%"
    >
      <MultipleForm @cancel="onFormCancel" @success="onFormSuccess" />
    </el-dialog>
    <el-dialog
      title="通知"
      :visible.sync="messageDialog"
      append-to-body
      :close-on-click-modal="false"
      width="90%"
    >
      <Message @close="messageDialog = false" />
    </el-dialog>
    <el-dialog
      title="新增提醒"
      :visible.sync="messageFormDialog"
      append-to-body
      :close-on-click-modal="false"
      width="800px"
    >
      <MessageForm
        :id="currentRow.id"
        :name="currentRow.clientName"
        @success="messageFormSuccess"
        @cancel="messageFormCancel"
      />
    </el-dialog>
    <el-dialog
      title="通知提醒"
      :visible.sync="messageFormRemindDialog"
      append-to-body
      :close-on-click-modal="false"
      width="70%"
    >
      <MessageRemind
        @showdateil="handleShowInvitationDetail"
        @close="messageFormRemindDialog = false"
      />
    </el-dialog>
  </section>
</template>

<script>
import BasicTable from "@/components/BasicTable";
import { GetClientInvitationsList } from "./api";
import { GetClientInvitationsMessagesList } from "./api/message";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
import { checkPermission } from "@/utils/auth";
import { GetTableOrderParams, ReadLocalColumn } from "@/utils/table";
import Detail from "./components/Detail";
import Form from "./components/Form";
import MultipleForm from "./components/MultipleForm";
import FilterSection from "./components/FilterSection";
import Message from "./components/Message";
import MessageForm from "./components/MessageForm";
import MessageRemind from "./components/MessageRemind";
import comonControl from "./mixins/comonControl";
import PageHeader from "@/components/PageHeader";
import config from "@/config";
export default {
  name: "ClientInvitationsList",
  components: {
    Message,
    MessageForm,
    MessageRemind,
    FilterSection,
    BasicTable,
    MultipleForm,
    Form,
    PageHeader,
    Detail,
  },
  mixins: [comonControl],
  data() {
    return {
      messageFormRemindDialog: false,
      messageFormDialog: false,
      messageDialog: false,
      formDialog: false,
      detaiDialog: false,
      tableLoading: false,
      multipleFormDialog: false,
      tableColumns: [],
      page: 0,
      size: config.TABLE_SIZE,
      total: 0,
      tableData: [],
      tableFilters: {},
      orderProp: "createdAt",
      orderDirection: "descending",
      currentId: "",
      currentRow: {},
      multipleSelected: [],
      orderPropObj: {},
    };
  },
  created() {
    this.buildTableColumns();
    // this.getTableData();
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.name === "ClientInvitationsList") {
          this.checkMessageRemindStatus();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    isEmprty,
    checkPermission,
    buildTableColumns(columns = []) {
      if (columns.length > 0) {
        this.tableColumns = columns;
      } else {
        let columns = [
          {
            prop: "id",
            label: "ID",
            showColumn: false,
            width: 100,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "disabled",
            label: "状态",
            showColumn: true,
            width: 100,
            fixed: "left",
            align: "center",
            sortable: false,
          },
          {
            prop: "clientName",
            label: "客户名称",
            minWidth: 150,
            showColumn: true,
            sortable: "custom",
          },
          {
            prop: "typeName",
            label: "邀约类型",
            showColumn: true,
            minWidth: 120,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "status",
            label: "考察情况",
            showColumn: true,
            minWidth: 120,
            align: "center",
            sortable: "custom",
          },

          {
            prop: "visitDate",
            label: "客户考察日期",
            showColumn: true,
            minWidth: 130,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "changedVisitDate",
            label: "客户改期日期",
            showColumn: true,
            minWidth: 150,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "visitStatusName",
            label: "到访情况",
            showColumn: true,
            minWidth: 120,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "remindDate",
            label: "提醒日期",
            showColumn: true,
            minWidth: 130,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "visitorName",
            label: "到访人",
            showColumn: true,
            minWidth: 150,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "followUpUsers",
            label: "接待人",
            showColumn: true,
            minWidth: 150,
            align: "left",
            sortable: false,
          },
          {
            prop: "clientOwnerName",
            label: "负责人",
            showColumn: true,
            minWidth: 150,
            align: "left",
            sortable: false,
          },
          {
            prop: "clientOwnerDepartmentName",
            label: "部门",
            showColumn: true,
            width: 150,
            align: "left",
            sortable: false,
          },
          {
            prop: "remark",
            label: "备注",
            showColumn: true,
            width: 150,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "createdAt",
            label: "创建日期",
            showColumn: true,
            width: 200,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "createdByName",
            label: "创建人",
            align: "center",
            showColumn: true,
            width: 120,
            sortable: "custom",
          },
          {
            prop: "lastModifiedAt",
            label: "最后修改日期",
            showColumn: true,
            width: 200,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "lastModifiedByName",
            label: "最后修改人",
            align: "center",
            showColumn: true,
            width: 120,
            sortable: "custom",
          },
          {
            prop: "controlColumn",
            fixed: "right",
            showColumn: true,
            label: "操作",
            width: 125,
          },
        ];
        this.tableColumns = ReadLocalColumn(this.$route, columns);
      }
    },
    handleSizeChange(val) {
      this.page = 0;
      this.size = val;
      this.getTableData();
    },
    handlePageChange(val) {
      this.page = val - 1;
      this.getTableData();
    },
    filterParams() {
      return {
        ...this.tableFilters,
      };
    },
    getTableData() {
      this.tableLoading = true;
      GetClientInvitationsList({
        page: this.page,
        size: this.size,
        sort: GetTableOrderParams(
          this.orderProp,
          this.orderDirection,
          this.orderPropObj
        ),
        ...this.filterParams(),
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content.map((item) => {
            return {
              ...item,
              canDisableChecking: false,
              canUpdateChecking: false,
              canUpdate: false,
              canDisable: false,
              isEdit: false,
            };
          });
          this.total = totalElements;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("拉取列表错误", err);
        });
    },
    onRefresh() {
      this.getTableData();
    },
    onClearAndRefresh() {
      this.orderProp = "";
      this.orderDirection = null;
      this.onRefresh();
    },
    onFilterChange(data) {
      this.tableFilters = data;
      this.onRefresh();
    },
    onTableSortChange({ prop, order }) {
      if (order === null) {
        this.orderProp = "createdAt";
        this.orderDirection = "descending";
      } else {
        this.orderProp = prop;
        this.orderDirection = order;
      }
      this.page = 0;
      this.onRefresh();
    },
    addRow() {
      this.formDialog = true;
    },
    showRow(row) {
      this.currentId = row.id;
      this.detaiDialog = true;
    },
    handleShowInvitationDetail(id) {
      this.currentId = id;
      this.detaiDialog = true;
    },

    showClientDetail(row) {
      this.$router.push({
        name: "CientsDetail",
        params: {
          id: row.clientId,
          invitations: true,
          tabId: 4,
        },
      });
    },
    onFormCancel() {
      this.currentId = "";
      this.multipleFormDialog = false;
      this.formDialog = false;
    },
    onFormSuccess() {
      if (!isEmprty(this.currentId)) {
        this.formDialog = false;
        this.onRefresh();
      } else {
        this.page = 0;
        this.onRefresh();
      }
      this.currentId = "";
    },
    onSelectionChange(selections) {
      this.multipleSelected = selections;
    },
    handleShowMessage() {
      this.messageDialog = true;
    },
    handleMessageAdd(row) {
      this.currentRow = row;
      this.messageFormDialog = true;
    },
    messageFormSuccess() {
      this.currentRow = {};
      this.messageFormDialog = false;
    },
    messageFormCancel() {
      this.currentRow = {};
      this.messageFormDialog = false;
    },
    checkMessageRemindStatus() {
      GetClientInvitationsMessagesList({
        page: 0,
        size: 2000,
        isRead: false,
        needRemind: true,
      }).then((res) => {
        if (res.data.content.length > 0) {
          this.messageFormRemindDialog = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>