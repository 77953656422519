<template>
  <el-form
    :model="form"
    ref="form"
    label-width="100px"
    class="company-ruleForm"
    label-position="top"
    :disabled="submitting"
  >
    <!-- <el-alert
      :closable="false"
      title="Tips：输入完成后敲击回车可直接新增一行"
      type="info"
    >
    </el-alert> -->
    <el-row
      :gutter="10"
      v-for="(item, index) in form.items"
      :key="item.key"
      class="base-shadow"
      style="margin-bottom: 15px; padding: 0 10px"
    >
      <el-col :sm="24" :md="12" :lg="4">
        <el-form-item
          label="客户"
          :prop="'items.' + index + '.clientId'"
          :rules="[
            { required: true, message: '请选择客户', trigger: 'change' },
          ]"
        >
          <BasicSelect v-model="item.clientId" :allOptions="_clientOptions" />
        </el-form-item>
      </el-col>
      <el-col :sm="24" :md="12" :lg="2">
        <el-form-item
          label="接待人"
          :prop="'items.' + index + '.followUpUserIds'"
          :rules="[
            { required: true, message: '请选择接待人', trigger: 'change' },
          ]"
        >
          <BasicSelect
            :multiple="true"
            v-model="item.followUpUserIds"
            :allOptions="ownerOptions"
          />
        </el-form-item>
      </el-col>
      <el-col :sm="24" :md="12" :lg="2">
        <el-form-item
          label="客户考察日期"
          :prop="'items.' + index + '.visitDate'"
          :rules="[
            { required: true, message: '请选择考察日期', trigger: 'change' },
          ]"
        >
          <el-date-picker
            style="width: 100%"
            v-model="item.visitDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="点击选择"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :sm="24" :md="12" :lg="2">
        <el-form-item
          label="邀约类型"
          :prop="'items.' + index + '.typeId'"
        >
          <BasicSelect v-model="item.typeId" :allOptions="typeOptions" />
        </el-form-item>
      </el-col>
      <el-col :sm="24" :md="12" :lg="2">
        <el-form-item
          label="考察情况"
          :prop="'items.' + index + '.statusId'"
          :rules="[
            { required: true, message: '请选择考察情况', trigger: 'change' },
          ]"
        >
          <BasicSelect v-model="item.statusId" :allOptions="statusesOptions" />
        </el-form-item>
      </el-col>
      <el-col :sm="24" :md="12" :lg="2" v-if="item.statusId === 'CHANGE'">
        <el-form-item
          label="改期时间"
          :prop="'items.' + index + '.changedVisitDate'"
          :rules="[
            { required: true, message: '请选择改期时间', trigger: 'change' },
          ]"
        >
          <el-date-picker
            style="width: 100%"
            v-model="item.changedVisitDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="点击选择"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :sm="24" :md="12" :lg="2">
        <el-form-item label="提醒日期" :prop="'items.' + index + '.remindDate'">
          <el-date-picker
            style="width: 100%"
            v-model="item.remindDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="点击选择"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :sm="24" :md="12" :lg="4">
        <el-form-item label="到访人" :prop="'items.' + index + '.visitorName'">
          <el-input
            v-model="item.visitorName"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
      </el-col>

      <el-col :sm="24" :md="12" :lg="3">
        <el-form-item label="备注" :prop="'items.' + index + '.remark'">
          <el-input
            v-model="item.remark"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :sm="24" :md="12" :lg="2" v-if="form.items.length > 1">
        <el-form-item label="操作" prop="userCountLimit"
          ><el-button
            type="danger"
            @click="onDeleteRow(index)"
            :disabled="submitting"
            >删除</el-button
          ></el-form-item
        >
      </el-col>
    </el-row>
    <el-form-item style="text-align: right; margin-top: 30px">
      <el-button @click="onCancel" :disabled="submitting">关 闭</el-button>
      <el-button @click="addRow" :disabled="submitting">新增一行</el-button>
      <el-button
        type="primary"
        :loading="submitting"
        @click="submitForm('form')"
        >{{ submitting ? "提交中..." : "立即提交" }}</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import { createUniqueString } from "@/utils/common";
import { MultiplePostClientInvitations } from "../api";
import { ShowApiError } from "@/request/error";
import formSelection from "../mixins/formSelection";
import BasicSelect from "@/components/BasicSelect";
export default {
  components: { BasicSelect },
  mixins: [formSelection],
  data() {
    return {
      submitting: false,
      focusIndex: 0,
      form: {
        items: [
          {
            clientId: null,
            followUpUserIds: [],
            remark: "",
            visitDate: "",
            remindDate: "",
            visitorName: "",
            statusId: "",
            typeId: null,
            changedVisitDate: "",
            key: this.createUniqueString(),
          },
        ],
      },
    };
  },
  created() {
    this.initFormSelection();
  },
  methods: {
    createUniqueString,
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let datas = [];
          this.form.items.forEach((item) => {
            datas.push({
              clientId: item.clientId,
              followUpUserIds: item.followUpUserIds,
              visitDate: item.visitDate,
              remindDate: item.remindDate,
              visitorName: item.visitorName,
              statusId: item.statusId,
              typeId: item.typeId,
              changedVisitDate: item.changedVisitDate,
              remark: item.remark,
            });
          });
          this.submitting = true;
          MultiplePostClientInvitations(datas)
            .then(() => {
              this.submitting = false;
              this.initForm();
              this.$message.success("新增成功");
              this.$emit("success");
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("批量新增异常", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    initForm() {
      this.form = {
        items: [
          {
            clientId: null,
            followUpUserIds: [],
            remark: "",
            visitDate: "",
            remindDate: "",
            visitorName: "",
            statusId: "",
            typeId: null,
            changedVisitDate: "",
            key: this.createUniqueString(),
          },
        ],
      };
    },
    addRow(inputObj = "") {
      this.form.items.push({
        clientId: null,
        followUpUserIds: [],
        remark: "",
        visitDate: "",
        remindDate: "",
        visitorName: "",
        statusId: "",
        typeId: null,
        changedVisitDate: "",
        key: this.createUniqueString(),
      });
      this.$nextTick(() => {
        if (this.$refs[inputObj]) {
          this.$refs[inputObj][0].focus();
        }
      });
    },
    onDeleteRow(index) {
      if (this.form.items.length > 1) {
        this.form.items.splice(index, 1);
      }
    },
    onCancel() {
      this.initForm();
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.form-tips {
  font-size: 13px;
  text-align: left;
  padding: 10px;
}
</style>