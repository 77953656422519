<template>
  <el-form
    :model="form"
    :rules="rules"
    ref="form"
    label-width="100px"
    class="demo-ruleForm"
    :disabled="submitting"
  >
    <el-form-item label="邀约" prop="clientInvitationId">
      <span>{{ id }} - {{ name }}</span>
    </el-form-item>
    <el-form-item label="内容" prop="content">
      <el-input
        type="textarea"
        :rows="2"
        placeholder="请输入内容"
        clearable
        v-model="form.content"
      >
      </el-input>
    </el-form-item>
    <el-form-item label="提醒人" prop="remindForUserIds">
      <BasicSelect
        :multiple="true"
        v-model="form.remindForUserIds"
        :allOptions="ownerOptions"
      />
    </el-form-item>
    <el-form-item style="text-align: right">
      <el-button @click="onCancel('form')">关 闭</el-button>
      <el-button
        type="primary"
        @click="submitForm('form')"
        :loading="submitting"
        >{{ submitting ? "提交中..." : "提交创建" }}</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import BasicSelect from "@/components/BasicSelect";
import { PostClientInvitationsMessages } from "../api/message";
import { GetClientOwnersAll } from "@/views/client/clientOwners/api";
import { ShowApiError } from "@/request/error";
export default {
  components:{BasicSelect},
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
    name: { type: [String], default: "未知" },
  },
  data() {
    return {
      submitting: false,
      form: {
        clientInvitationId: null,
        content: "",
        remindForUserIds: [],
      },
      rules: {
        clientInvitationId: [
          { required: true, message: "请先选择邀约", trigger: "blur" },
        ],
        content: [
          {
            required: true,
            message: "请输入内容",
            trigger: ["blur", "change"],
          },
        ],
      },
      ownerOptions:[]
    };
  },
  watch: {
    id: {
      handler() {
        if (this.id && this.id !== null) {
          this.form.clientInvitationId = this.id;
        } else {
          this.form.clientInvitationId = null;
        }
      },
      immediate: true,
    },
  },
  created(){
    this.initFormSelection()
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PostClientInvitationsMessages(this.form)
            .then(() => {
              this.submitting = false;
              this.initForm();
              this.$message.success("发布成功!");
              this.$store.dispatch('app/getGlobalMessageRemindCount')
              this.emitSucccess();
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("发布失败", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    onCancel() {
      this.initForm();
      this.emitFail();
    },
    initForm() {
      this.form = {
        clientInvitationId: (this.id && this.id !== null) ? this.id : null,
        content: "",
        remindForUserIds: [],
      };
    },
    initFormSelection() {
      GetClientOwnersAll().then((res) => {
        this.ownerOptions = res.data;
      });
    },
    emitSucccess() {
      this.$emit("success");
    },
    emitFail() {
      this.$emit("cancel");
    },
  },
};
</script>

<style>
</style>